import React, { useEffect } from "react"

const ErrorPage = () => {
  if (typeof window !== `undefined`) window.location.replace(`/`) // highlight-line

  useEffect(() => {})
  return (
    <div className={"error-container"}>
      <h4>404</h4>
      <p>la page n'est pas disponible</p>
    </div>
  )
}

export default ErrorPage
